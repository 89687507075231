<template>
<div class="guest-container">
    <TourialLogo />
    <div style="width: 100%;">
        <h3 class="bold">{{ $t('guest.title') }}</h3>
        <p>{{ $t('guest.caption') }}</p>
    </div>

    <vs-button size="large" block class="bold" @click="$router.push({path: '/explore/welcome'})">{{ $t('guest.button') }}</vs-button>

</div>
</template>

    
<script>
import TourialLogo from '../TourialLogo.vue';

export default {
    name: 'guest',
    props: {},
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {
        //this.email = this.iEmail;
    },
    components: {
        TourialLogo
    }
}
</script>

    
<style scoped>
.guest-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

/* DESKTOP */
@media (min-width: 1024px) {
    .guest-container {
        width: 350px;
    }
}

/* MOBILE */
@media (max-width: 600px) {
    .guest-container {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        width: 100% !important;

    }
}
</style>
